import React from 'react';
import k1 from '../images/kidsCoding1.jpg';
import k2 from '../images/kidsCoding2.jpg';
import k3 from '../images/kidsCoding3.jpg';
import k4 from '../images/kidsCoding4.jpg';
import k5 from '../images/kidsCoding5.jpg';

const Introduction = () => {
  return (
    <div className='max-w-[1400px] m-auto py-16 px-4 grid lg:grid-cols-2 gap-8'>
      {/* Left Side */}
      <div className='grid grid-cols-2 grid-rows-4 h-[40vh] lg:h-auto'>
        <img
          className='row-span-2 object-cover w-full h-full p-2'
          src={k2}
          alt='Kids Coding 2'
        />
        <img
          className='row-span-2 object-cover w-full h-full p-2'
          src={k3}
          alt='Kids Coding 3'
        />
        <img
          className='row-span-3 object-cover w-full h-full p-2'
          src={k4}
          alt='Kids Coding 4'
        />
        <img
          className='row-span-2 object-cover w-full h-full p-2'
          src={k5}
          alt='Kids Coding 5'
        />
      </div>
      {/* Right Side */}
      <div className='flex flex-col h-full justify-center'>
        <h3 className='text-4xl md:text-5xl lg:text-6xl font-bold'>
          Kids can code!
        </h3>
        <p className='text-lg md:text-xl py-6'>
          Unlock your child's potential in the digital world with fun and engaging coding lessons.
        </p>
        <p className='pb-6'>
          At Coding Hamsters, we believe every child has the potential to become a tech whiz. Our mission is to make coding accessible and enjoyable for kids, nurturing the next generation of digital creators and innovators. Our carefully designed curriculum combines play and learning, ensuring that children not only acquire valuable skills but also develop a love for technology. We are committed to providing a supportive environment where curiosity and creativity can flourish.
        </p>
        <p className='text-sm md:text-base lg:text-lg'>
          Start your coding journey with Scratch, a visual programming language designed for young learners. Through interactive projects, kids will learn the basics of coding, logic, and creativity. They'll create their own games, animations, and stories while learning essential coding concepts like loops, conditionals, and variables. Our step-by-step approach ensures that even beginners can follow along and achieve amazing results, boosting their confidence and problem-solving skills.
        </p>
      </div>
    </div>
  );
};

export default Introduction;
