import React from 'react';

const WhyUs = () => {
  return (
    <div className='max-w-[1400px] m-auto py-16 px-4'>
      <div className='flex flex-col h-full justify-center'>
        <h3 className='text-4xl md:text-5xl lg:text-6xl font-bold py-4 text-center'>
          Why Choose Coding Hamsters?
        </h3>
        <ul className='list-disc list-inside space-y-6 text-lg md:text-xl'>
          <li className='py-2'>
            <span className='font-bold'>Fun and Interactive Lessons:</span> Our curriculum is designed to be engaging and enjoyable, ensuring kids have fun while learning. We use games, challenges, and interactive projects to keep children motivated and excited about coding.
          </li>
          <li className='py-2'>
            <span className='font-bold'>Expert Instructors:</span> Our team of experienced educators and tech enthusiasts are dedicated to providing top-notch education. With a passion for teaching and a deep understanding of technology, our instructors guide students through their learning journey with patience and enthusiasm.
          </li>
          <li className='py-2'>
            <span className='font-bold'>Real-World Skills:</span> We equip kids with valuable skills that are relevant in today’s tech-driven world. Beyond coding, our courses foster abilities such as creativity, critical thinking, problem-solving, and comfort with numbers that will support them in all areas of life.
          </li>
          <li className='py-2'>
            <span className='font-bold'>Safe Learning Environment:</span> We prioritize the safety and well-being of our students, ensuring a secure and supportive space for learning. Our classes are designed to be inclusive and respectful, promoting a positive learning atmosphere where every child feels valued and encouraged.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default WhyUs;
