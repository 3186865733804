import React from 'react';
import k1 from '../images/kidsCoding1.jpg'
import k2 from '../images/kidsCoding2.jpg'
import k3 from '../images/kidsCoding3.jpg'
import k4 from '../images/kidsCoding4.jpg'
import k5 from '../images/kidsCoding5.jpg'
import FanAndLamp from '../images/Lamp_Fan_Demo.jpg'
import KidsAndAi from '../images/kidsAndAI.jpg'

const MachineLearning = () => {

    return (
        <div className='max-w-[1400px] m-auto py-16 px-4 grid lg:grid-cols-2 gap-4'>
          {/* Left Side */}
          <div className='grid grid-cols-1 grid-rows-1 h-[80vh]'>
          
            <img
              className='row-span-3 object-contain w-full h-full p-2'
              src={KidsAndAi}
              alt='/'
            />
            
            
          </div>
          
          {/* Right Side */}
          <div className='flex flex-col h-full justify-center'>
            <h3 className='text-5xl md:text-6xl font-bold'>Futureproof with introduction to AI!</h3>
              <p className='text-2xl py-6'>
                Start introducing your kids to different AI tools to enhance their experience with them when they are older
              </p>            
              
              <h3 className='text-1xl py-3 font-bold'>Prompt Engineering</h3>
              <p>
              Discover the power of communication with computers. Our prompt engineering lessons teach kids how to interact with AI models, creating smart and responsive systems. Students will learn how to craft precise prompts to get the desired output from AI, enhancing their understanding of human-computer interaction. This course emphasizes creativity and clarity in communication, encouraging kids to experiment with different ways of guiding AI behavior. By the end of the course, they will have a toolkit of techniques to create interactive and intelligent digital experiences.
              </p>
              
              <h3 className='text-1xl py-3 font-bold'>Introduction to Machine Learning</h3>
              
              <p>
              Step into the future with our beginner-friendly machine learning course. Kids will explore how machines learn from data and get hands-on experience with simple ML projects. They'll understand concepts like training, testing, and model evaluation in an accessible and engaging way. Using kid-friendly tools, they'll build their own machine learning models to recognize patterns, classify data, and make predictions. This course demystifies machine learning and empowers children to use it in their own creative projects.
              </p>
              <h3 className='text-1xl py-3 font-bold'>Exploring Artificial Intelligence</h3>
              <p>
              Delve into the world of AI and discover its incredible applications. From chatbots to image recognition, kids will see how AI is shaping our world and get to build their own AI projects. They'll learn about different types of AI, such as rule-based systems and neural networks, and understand how these technologies are used in everyday life. Through hands-on activities, they'll create AI-powered projects that showcase their newfound skills, inspiring them to think about the ethical and societal implications of AI.
              
              </p>
                        
          </div>
        </div>
      );
}

export default MachineLearning