import React, { useState } from 'react';

const Plan = () => {
  const [selectedButton, setSelectedButton] = useState(1);

  const renderContent = () => {
    switch (selectedButton) {
      case 1:
        return (
          <div className='flex flex-col h-full justify-center'>
            <iframe
              src='https://scratch.mit.edu/projects/1027507774/embed'
              allowtransparency='true'
              width='485'
              height='402'
              frameborder='0'
              scrolling='no'
              allowfullscreen
              className='rounded-lg'
            ></iframe>
          </div>
        );
      case 2:
        return (
          <div className='flex flex-col h-full justify-center'>
            <iframe
              src='https://scratch.mit.edu/projects/1028141767/embed'
              allowtransparency='true'
              width='485'
              height='402'
              frameborder='0'
              scrolling='no'
              allowfullscreen
              className='rounded-lg'
            ></iframe>
          </div>
        );
      case 3:
        return (
          <div className='flex flex-col h-full justify-center'>
            <iframe
              src='https://scratch.mit.edu/projects/1046677935/embed'
              allowtransparency='true'
              width='485'
              height='402'
              frameborder='0'
              scrolling='no'
              allowfullscreen
              className='rounded-lg'
            ></iframe>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='max-w-[1400px] m-auto py-16 px-4 grid lg:grid-cols-2 gap-8'>
      {/* Left Side */}
      <div className='flex flex-col h-full justify-center'>
        <h3 className='text-4xl md:text-5xl lg:text-6xl font-bold'>
          Try our game now!
        </h3>
        <p className='text-lg md:text-xl lg:text-2xl py-4'>
          Want to build a similar project?
        </p>
        <p className='pb-4'>
          Join our classes and experience your child’s first coding lesson. We have comprehensive lesson plans tailored to engage kids and help them unleash their creativity.
        </p>
        <p className='pb-4'>
          You can try our sample projects here. Just select the difficulty level and click the green flag to start.
        </p>
        <div className='flex flex-col gap-2'>
          <button
            className={`p-2 ${selectedButton === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setSelectedButton(1)}
          >
            Easy
          </button>
          <button
            className={`p-2 ${selectedButton === 2 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setSelectedButton(2)}
          >
            Medium
          </button>
          <button
            className={`p-2 ${selectedButton === 3 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setSelectedButton(3)}
          >
            Hard
          </button>
        </div>
      </div>

      {/* Right Side */}
      <div className='flex justify-center items-center'>
        {renderContent()}
      </div>
    </div>
  );
};

export default Plan;
