import Navbar from './components/Navbar.jsx';
import Hero from './components/Hero.jsx';
import Introduction from './components/Introduction.jsx';
import Plan from './components/Plan.jsx';
import ImageSlider from './components/ImageSlider.jsx'
import Footer from './components/Footer.jsx'
import MachineLearning from './components/MachineLearning.jsx';
import WhyUs from './components/WhyUs.jsx';
import ChatBot from 'react-simple-chatbot';
import Avatar from './images/Avatar.jpg'
import React from 'react'
 
//React.StrictMode = false;
const steps = [
  {
      id: '0',
      message: 'Hey Young Coder!',
      trigger: '1',
  }, {
      id: '1',
      message: 'Please write your username',
      trigger: '2'
  }, {
      id: '2',
      user: true,
      trigger: '3',
  }, {
      id: '3',
      message: 'hi {previousValue}, how can I help you?',
      trigger: '4'
  }, {
      id: '4',
      options: [

          { value: 1, label: 'Send Email' },
          { value: 2, label: 'View Programmes' },

      ],
      end: true
  }
];

const config = {
  botAvatar: Avatar,
  floating: true,
};

function App() {
  return (
    <div>
       {/* <ChatBot 
       headerTitle="Hamster Bot"
       steps={steps}
       {...config}
        /> */}
      {/* <Navbar /> */}
      <Hero />
      <Introduction />
      <Plan />
      <MachineLearning />
      <WhyUs />
      <ImageSlider />
      <Footer />
    </div>
  );
}

export default App;
