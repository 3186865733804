import React from 'react';
import Slider from "react-slick";
import CardDetails from './CardDetails';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import LukProfilePhoto from '../images/profile_photo_luk.jpg';
import NabilProfilePhoto from '../images/profile_photo_nabil.jpg';

const ImageSlider = () => {
  const slides = [
    {
      name: 'Lukasz',
      title: 'Co-Founder / Chief Hamster',
      image: LukProfilePhoto,
      email: 'lukasz.cykowski@codinghamsters.ai',
      about: 'Results-driven Lead Software Engineer with experience in Application Development and DevOps across the banking and technology sectors. Demonstrated ability to optimize processes, establish secure development environments, and deliver top-tier software solutions. Proven team player with exceptional problem-solving skills.',
    },
    {
      name: 'Nabil',
      title: 'Co-Founder / Chair Hamster',
      image: NabilProfilePhoto,
      email: 'nabil@codinghamsters.ai',
      about: 'A serial investor currently prioritising on his next triathlon race, Nabil is a keen learner from his 2 beautiful children on what the future holds, especially from tech and AI perspectives. He is also a Deputy Group CEO in one of the largest clinical laboratory platforms in Asia.',
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className='max-w-4xl mx-auto py-16 px-4'>
      <div className='flex flex-col h-full justify-center'>
        <h3 className='text-5xl md:text-6xl font-bold text-center mb-8'>Get to know our Hamsters!</h3>
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index} className='p-4'>
              <CardDetails
                image={slide.image}
                name={slide.name}
                title={slide.title}
                about={slide.about}
                email={slide.email}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ImageSlider;
