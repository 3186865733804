import React from 'react';

const CardDetails = ({ image, name, title, about, email }) => {
  return (
    <div className='flex flex-col items-center bg-white rounded-xl border shadow-md p-4'>
      <div className="flex flex-col items-center">
        <img
          src={image}
          alt={`${name}'s profile`}
          className="rounded-full w-32 h-32 md:w-40 md:h-40 object-cover"
        />
      </div>
      <div className="text-center mt-4">
        <h2 className="text-xl md:text-2xl font-semibold">{name}</h2>
        <h3 className="text-lg md:text-xl font-medium text-gray-600">{title}</h3>
        <p className="text-sm md:text-base mt-2 text-gray-700">{about}</p>
        <p className="text-sm mt-2 text-blue-600">{email}</p>
      </div>
    </div>
  );
};

export default CardDetails;
