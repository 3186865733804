import React from 'react';
import banner from '../images/HamstersBanner.jpg';
import logo from '../images/CodingHamstersLogo.jpg';

const Hero = () => {
  return (
    <div className='w-full h-screen relative'>
      <img
        className='w-full h-full object-cover'
        src={logo}
        alt='Coding Hamsters Logo'
      />
      <div className='bg-black/30 absolute top-0 left-0 w-full h-full' />
      <div className='absolute top-0 w-full h-full flex flex-col justify-center text-white text-center md:text-left'>
        <div className='md:left-[10%] max-w-[1100px] m-auto p-4'>
          <p className='text-lg md:text-xl'>Welcome to</p>
          <h1 className='font-bold text-4xl md:text-6xl drop-shadow-2xl'>
            Coding Hamsters Experience
          </h1>
          <p className='max-w-[600px] drop-shadow-2xl py-2 text-lg md:text-xl'>
            Welcome to our site where you can learn more about what your kids can learn!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;